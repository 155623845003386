/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuizAnswer } from '../models/QuizAnswer';
import type { QuizChapter } from '../models/QuizChapter';
import type { StudentQuizResult } from '../models/StudentQuizResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class QuizService {

    /**
     * Upsert Quiz Answer
     * @param requestBody
     * @returns QuizAnswer Successful Response
     * @throws ApiError
     */
    public static upsertQuizAnswerApiQuizAnswerPost(
        requestBody: QuizAnswer,
    ): CancelablePromise<QuizAnswer> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/quiz/answer',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quiz Answers By Student And Course
     * @param studentId
     * @param courseId
     * @returns QuizAnswer Successful Response
     * @throws ApiError
     */
    public static getQuizAnswersByStudentAndCourseApiQuizAnswersStudentIdCourseIdGet(
        studentId: string,
        courseId: string,
    ): CancelablePromise<Array<QuizAnswer>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/quiz/answers/{student_id}/{course_id}',
            path: {
                'student_id': studentId,
                'course_id': courseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quiz Questions By Course
     * @param courseId
     * @returns QuizChapter Successful Response
     * @throws ApiError
     */
    public static getQuizQuestionsByCourseApiQuizQuestionsCourseIdGet(
        courseId: string,
    ): CancelablePromise<Array<QuizChapter>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/quiz/questions/{course_id}',
            path: {
                'course_id': courseId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Quiz Questions By Chapter
     * @param courseId
     * @param chapterId
     * @returns QuizChapter Successful Response
     * @throws ApiError
     */
    public static getQuizQuestionsByChapterApiQuizQuestionsCourseIdChapterIdGet(
        courseId: string,
        chapterId: string,
    ): CancelablePromise<QuizChapter> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/quiz/questions/{course_id}/{chapter_id}',
            path: {
                'course_id': courseId,
                'chapter_id': chapterId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Class Summary
     * @param classId
     * @param courseId
     * @param chapterId
     * @returns StudentQuizResult Successful Response
     * @throws ApiError
     */
    public static getClassSummaryApiQuizClassSummaryClassIdCourseIdChapterIdGet(
        classId: string,
        courseId: string,
        chapterId: string,
    ): CancelablePromise<Array<StudentQuizResult>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/quiz/class_summary/{class_id}/{course_id}/{chapter_id}',
            path: {
                'class_id': classId,
                'course_id': courseId,
                'chapter_id': chapterId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
