/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentUserAndStudentResponse } from '../models/CurrentUserAndStudentResponse';
import type { LoginForm } from '../models/LoginForm';
import type { LoginResponse } from '../models/LoginResponse';
import type { OrgStudentLoginForm } from '../models/OrgStudentLoginForm';
import type { SignupForm } from '../models/SignupForm';
import type { TeacherSchema } from '../models/TeacherSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Current User
     * @returns CurrentUserAndStudentResponse Successful Response
     * @throws ApiError
     */
    public static currentUserApiAuthCurrentGet(): CancelablePromise<CurrentUserAndStudentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/current',
        });
    }

    /**
     * Login
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static loginApiAuthLoginPost(
        requestBody: LoginForm,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login Organization Student
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static loginOrganizationStudentApiAuthLoginOrgPost(
        requestBody: OrgStudentLoginForm,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/login-org',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Choose Student
     * @param studentId
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static chooseStudentApiAuthChooseStudentStudentIdPost(
        studentId: string,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/choose_student/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Google Callback
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static googleCallbackApiAuthGoogleCallbackGet(): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/google_callback',
        });
    }

    /**
     * Google Authorize
     * @returns any Successful Response
     * @throws ApiError
     */
    public static googleAuthorizeApiAuthGoogleAuthorizeGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/google/authorize',
        });
    }

    /**
     * Confirm Email
     * @param token
     * @returns any Successful Response
     * @throws ApiError
     */
    public static confirmEmailApiAuthConfirmEmailGet(
        token: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/confirm-email',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * @returns any Successful Response
     * @throws ApiError
     */
    public static logoutApiAuthLogoutGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/logout',
        });
    }

    /**
     * Generate Student Username
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateStudentUsernameApiAuthGenerateUsernameGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/generate-username',
        });
    }

    /**
     * Signup
     * @param token
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static signupApiAuthSignupPost(
        token: string,
        requestBody: SignupForm,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/signup',
            query: {
                'token': token,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User By Token
     * @param token
     * @returns TeacherSchema Successful Response
     * @throws ApiError
     */
    public static getUserByTokenApiAuthGetUserByTokenGet(
        token: string,
    ): CancelablePromise<TeacherSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/get-user-by-token',
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Forgot Password
     * @param email
     * @returns any Successful Response
     * @throws ApiError
     */
    public static forgotPasswordApiAuthForgotPasswordPost(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/forgot-password',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @param token
     * @param newPassword
     * @returns any Successful Response
     * @throws ApiError
     */
    public static resetPasswordApiAuthResetPasswordPost(
        token: string,
        newPassword: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/reset-password',
            query: {
                'token': token,
                'new_password': newPassword,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Paid
     * @param email
     * @param paid
     * @returns any Successful Response
     * @throws ApiError
     */
    public static setPaidApiAuthSetPaidGet(
        email: string,
        paid: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/set-paid',
            query: {
                'email': email,
                'paid': paid,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check User Email Exists
     * @param email
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static checkUserEmailExistsApiAuthCheckUserEmailExistsGet(
        email: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/auth/check-user-email-exists',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Teacher Signup Link
     * @param email
     * @returns string Successful Response
     * @throws ApiError
     */
    public static generateTeacherSignupLinkApiAuthGenerateUserSignupLinkPost(
        email: string,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/generate-user-signup-link',
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Impersonate User
     * @param targetUserId
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static impersonateUserApiAuthImpersonateUserPost(
        targetUserId: string,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/impersonate/user',
            query: {
                'target_user_id': targetUserId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Impersonate Student
     * @param targetStudentId
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public static impersonateStudentApiAuthImpersonateStudentPost(
        targetStudentId: string,
    ): CancelablePromise<LoginResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/auth/impersonate/student',
            query: {
                'target_student_id': targetStudentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
