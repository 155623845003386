import { createContext, useContext, useState } from 'react';
import { LevelData } from '../client';

interface ApiLevelDataContextType {
  apiLevelData: LevelData | null;
  setApiLevelData: React.Dispatch<React.SetStateAction<LevelData | null>>;
}

const ApiLevelDataContext = createContext<ApiLevelDataContextType | null>(null);

export function useApiLevelData(): ApiLevelDataContextType {
  return useContext(ApiLevelDataContext) as ApiLevelDataContextType;
}

export function ApiLevelDataProvider({ children }: { children: React.ReactNode }) {
  const [apiLevelData, setApiLevelData] = useState<LevelData | null>(null);

  const value: ApiLevelDataContextType = {
    apiLevelData,
    setApiLevelData
  };

  return <ApiLevelDataContext.Provider value={value}>{children}</ApiLevelDataContext.Provider>;
}
