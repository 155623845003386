import React, { useContext } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography
} from '@mui/material';
import {
  AutoStories,
  Build,
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandMore,
  LockSharp,
  Login,
  School,
  ShoppingCart,
  SportsScore,
  Email
} from '@mui/icons-material';
import Footer from './Footer';
import { UserContext } from '../pages/App';
import { useSidebar } from '../context/SidebarContext';
// import { sessionUtil } from '../@core/utils/sessionStorage';
import CourseProgress from './CourseProgress';
import useLevels from '../hooks/useLevels';
import { useLocaleContext } from '../context/LocaleContext';
import { NAVBAR_PAGES } from './Header';

export default function Sidebar() {
  const currentUser = useContext(UserContext);
  const {
    openSidebar,
    sidebarWidth,
    handleDrawerTransitionEnd,
    handleDrawerClose,
    handleDrawerOpen
  } = useSidebar();

  const { courseData, courseDataLoading } = useLevels();

  const { getLocaleLink } = useLocaleContext();

  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  if (courseData === undefined) {
    return <div></div>;
  }

  const checkChapterCompleted = (chapterIndex: number) => {
    for (const levelData of courseData.chapters[chapterIndex].level_data) {
      if (!levelData.completed) {
        return false;
      }
    }
    return true;
  };

  return (
    <SwipeableDrawer
      anchor="left"
      open={openSidebar}
      onOpen={handleDrawerOpen}
      onTransitionEnd={handleDrawerTransitionEnd}
      onClose={handleDrawerClose}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: sidebarWidth
          // background: theme.palette.primary.main,
          // color: theme.palette.common.white,
        }
      }}
    >
      <Toolbar />
      <Box component="div" sx={{ p: 2 }}>
        <Button
          fullWidth
          variant="contained"
          href={getLocaleLink('/play/python/creative')}
          startIcon={<Build />}
        >
          Creative Mode
        </Button>
      </Box>

      <List disablePadding dense>
        {currentUser === undefined && (
          <ListItem dense sx={{ px: 0 }}>
            <ListItemButton href={getLocaleLink('/login')}>
              <ListItemIcon>
                <Login sx={{ minWidth: 32 }} />
              </ListItemIcon>
              <ListItemText>Log In</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {!currentUser?.is_student && (
          <ListItem dense sx={{ px: 0 }}>
            <ListItemButton href={getLocaleLink('/purchase')}>
              <ListItemIcon>
                <ShoppingCart sx={{ minWidth: 32 }} />
              </ListItemIcon>
              <ListItemText>Buy Course</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {!currentUser?.is_student && (
          <ListItem dense sx={{ px: 0 }}>
            <ListItemButton href={getLocaleLink('/schools')}>
              <ListItemIcon>
                <School sx={{ minWidth: 32 }} />
              </ListItemIcon>
              <ListItemText>Register Your Institution</ListItemText>
            </ListItemButton>
          </ListItem>
        )}
        {NAVBAR_PAGES.map(([page, location]) => (
          <ListItem dense key={page} sx={{ px: 0 }}>
            <ListItemButton href={getLocaleLink(location)}>
              <ListItemIcon>
                <SportsScore sx={{ minWidth: 32 }} />
              </ListItemIcon>
              <ListItemText>{page}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem dense sx={{ px: 0 }}>
          <ListItemButton href="mailto:support@codingforkids.io" target="_blank">
            <ListItemIcon>
              <Email sx={{ minWidth: 32 }} />
            </ListItemIcon>
            <ListItemText>Contact Us</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>

      <Divider sx={{ my: 1 }} />
      <Box component="div" sx={{ px: 2, pb: 2, pt: 1.5 }}>
        <Button
          fullWidth
          variant="outlined"
          href={getLocaleLink('/courses')}
          startIcon={<AutoStories />}
        >
          Choose Course
        </Button>
      </Box>

      <Box component="div" sx={{ px: 2 }}>
        <Typography variant="h6">{courseData.name}</Typography>
      </Box>
      <Box component="div" sx={{ p: 2 }}>
        <CourseProgress courseData={courseData} />
      </Box>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <List component="div" disablePadding dense>
          {courseData &&
            courseData?.chapters
              ?.filter((chapterData) => !chapterData.class_locked)
              ?.map((chapterData, chapterIndex) => (
                <Accordion
                  key={chapterData.id}
                  defaultExpanded={!checkChapterCompleted(chapterIndex)}
                  sx={{ backgroundColor: 'inherit' }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Stack>
                      <Typography variant="body1">{chapterData.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Chapter {chapterIndex + 1}
                      </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0 }}>
                    <List dense>
                      {chapterData.level_data.map((levelData, index) => (
                        <ListItem dense key={index} sx={{ px: 0 }}>
                          <ListItemButton
                            href={
                              levelData.locked
                                ? getLocaleLink('/purchase')
                                : getLocaleLink(`/play/${courseData.id}/${levelData.slug}`)
                            }
                          >
                            <ListItemIcon sx={{ minWidth: 32 }}>
                              {levelData.completed ? (
                                <CheckBox fontSize="small" sx={{ color: 'primary.main' }} />
                              ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              sx={{ my: 0 }}
                              primary={levelData.name}
                              secondary={`Level ${index + 1}`}
                              dir="auto"
                            />
                            {levelData.locked && (
                              <ListItemIcon>
                                <LockSharp fontSize="small" sx={{ color: 'text.secondary' }} />
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        </ListItem>
                      ))}
                      {currentUser?.class_details?.quiz && chapterData.quiz_exists && (
                        <ListItem dense sx={{ px: 0 }}>
                          <ListItemButton
                            href={getLocaleLink(
                              `/quiz/${courseData.id}/${chapterData.id}/question/1`
                            )}
                          >
                            <ListItemIcon sx={{ minWidth: 32 }}>
                              {chapterData.quiz_complete ? (
                                <CheckBox fontSize="small" />
                              ) : (
                                <CheckBoxOutlineBlank fontSize="small" />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              sx={{ my: 0 }}
                              primary={chapterData.name}
                              secondary="Quiz"
                            />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
        </List>

        <Box component="div" sx={{ flex: 1 }}></Box>
        <Footer />
      </Box>
    </SwipeableDrawer>
  );
}
