import React, { createContext, useState, useContext, FC, ReactNode } from 'react';

// Create the TipContext
const TipProviderContext = createContext<{
  tips: string[];
  setTips: (tips: string[]) => void;
  currentTip: string | null;
  currentTipIndex: number;
  nextTip: () => void;
  closeTip: () => void;
  setTip: (tip: string) => void;
  setTipOpen: (open: boolean) => void;
  setTipVisible: (visible: boolean) => void;
}>({
  tips: [],
  currentTip: '',
  currentTipIndex: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTips: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  nextTip: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeTip: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTip: (tip: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTipOpen: (open: boolean) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTipVisible: (visible: boolean) => {}
});

// TipProvider component to provide the context to the children
export const TipProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [tips, setTips] = useState(['run-first', 'insert-code', 'run-again']);

  const [currentTipIndex, setCurrentTipIndex] = useState(-1);
  const [tipVisible, setTipVisible] = useState(false);
  const [tipOpen, setTipOpen] = useState(false);

  const currentTip = tipVisible && tipOpen ? tips[currentTipIndex] : null;

  // Function to advance to the next tip
  const nextTip = () => {
    setTipOpen(true);
    // setTipVisible(true);
    if (currentTipIndex < tips.length - 1) {
      setCurrentTipIndex(currentTipIndex + 1);
    } else {
      setCurrentTipIndex(-1);
    }
  };

  const setTip = (tip: string) => {
    setTipOpen(true);
    // setTipVisible(true);
    setCurrentTipIndex(tips.indexOf(tip));
  };

  // Function to close tips
  const closeTip = () => {
    setTipVisible(false);
  };

  return (
    <TipProviderContext.Provider
      value={{
        tips,
        setTips,
        currentTipIndex,
        nextTip,
        closeTip,
        setTip,
        currentTip,
        setTipOpen,
        setTipVisible
      }}
    >
      {children}
    </TipProviderContext.Provider>
  );
};

// Custom hook to use the TipContext
export const useTipContext = () => {
  return useContext(TipProviderContext);
};
