import { MapData } from '../../client';

export type Matrix = number[][];

export const mapHelper = {
  getCollisionLayer: (mapData: MapData) => {
    return mapData.layers.find((layer) => layer.name === 'collisions');
  },
  getLayers: (mapData: MapData) => {
    const layerOrder = [
      'ground_layer',
      'ground_details',
      'middle_layer',
      'top_layer',
      'extra_layer'
    ];

    const layers = mapData.layers.filter((layer) => layer.name !== 'collisions');

    layers.sort((a, b) => layerOrder.indexOf(a.name) - layerOrder.indexOf(b.name));

    return layers.map((layer) => {
      const { width, height, data } = layer;
      const matrix: Matrix = [];
      if (!height || !width || !data) return matrix;

      for (let i = height - 1; i >= 0; i--) {
        const row = data.slice(i * width, (i + 1) * width);
        matrix.push(row);
      }
      return matrix;
    });
  },

  mergeLayersData: (layers: Matrix[]) => {
    const mergedLayers: Matrix = layers[0];
    layers.forEach((layer, index) => {
      layer.forEach((row, i) => {
        row.forEach((cell, j) => {
          if (cell !== 0) {
            mergedLayers[mergedLayers.length - 1 - i][j] = cell;
          }
        });
      });
    });
    return mergedLayers;
  },
  resolveMergedLayerData: (mergedLayer: Matrix) => {
    return mergedLayer.map((row, y) => row.map((type, x) => [type, x, y]));
  },
  resolveSpriteSheetData: (mergedLayer: Matrix, tilesetsFirstgid: { [key: number]: any }) => {
    const layerData = mapHelper.resolveMergedLayerData(mergedLayer);
    return layerData.map((row) => {
      return row.map(([type, x, y]) => {
        if (type === 0) return;
        const { tileset, selectedKey } = mapHelper.getTilesetInRange(type, tilesetsFirstgid);

        const columns = tileset.columns;
        const tilecount = tileset.tilecount;
        const rows = tilecount / columns;
        const tilesetImagePath = tileset.image;

        const imageFileName = tilesetImagePath.split('/').pop();
        const sheetData: any = {
          src: `/assets/tileset_png/${imageFileName}`,
          frameWidth: 16,
          frameHeight: 16,
          sheet: {
            [`${imageFileName}-${x}-${y}`]: []
          }
        };
        const tileId = type - selectedKey;
        const spritePosition = mapHelper.calculateSpriteCoordinates(tileId, columns, rows);

        sheetData.sheet[`${imageFileName}-${x}-${y}`] = [spritePosition];
        return [type, x, y, sheetData];
      });
    });
  },
  resolveImages: (mergedLayer: Matrix, tilesetsFirstgid: { [key: number]: any }) => {
    const layerData = mapHelper.resolveMergedLayerData(mergedLayer);
    return layerData.map((row) => {
      return row.map(([type, x, y]) => {
        if (type === 0) return;
        const { tileset } = mapHelper.getTilesetInRange(type, tilesetsFirstgid);
        const tilesetImagePath = tileset?.image;
        const imageFileName = tilesetImagePath.split('/').pop();
        return `/assets/tileset_png/${imageFileName}`;
      });
    });
  },

  getAllAssetUrls: (mapData: MapData, tilesetsFirstgid: { [key: number]: any }) => {
    const allLayersAssetUrls: string[] = [];
    mapHelper.getLayers(mapData).forEach((layer) => {
      const resolvedAssets = mapHelper.resolveImages(layer, tilesetsFirstgid);
      const assetUrls = Array.from(new Set(resolvedAssets.flat())) as string[];
      allLayersAssetUrls.push(...assetUrls);
    });
    let allAssetUrls = Array.from(new Set(allLayersAssetUrls)) as string[];
    allAssetUrls = allAssetUrls.filter((url) => url !== undefined);
    return allAssetUrls;
  },

  getTilesetInRange(key: number, tilesetsFirstgid: { [key: number]: any }) {
    const keys = Object.keys(tilesetsFirstgid)
      .map(Number)
      .sort((a, b) => a - b);
    // eslint-disable-next-line no-prototype-builtins
    if (tilesetsFirstgid.hasOwnProperty(key)) {
      return { tileset: tilesetsFirstgid[key], selectedKey: key };
    }

    let selectedKey = keys[0];

    for (let i = 0; i < keys.length; i++) {
      if (key < keys[i]) {
        break;
      }
      selectedKey = keys[i];
    }

    return { tileset: tilesetsFirstgid[selectedKey], selectedKey };
  },
  calculateSpriteCoordinates(tileId: number, width: number, height: number) {
    const x = tileId % width;
    const y = Math.floor(tileId / width);

    const yAdjusted = height - 1 - y;

    return [x, yAdjusted];
  },
  async getTilesetMapperDynamicJsonImports(tilesets: { firstgid: number; source: string }[]) {
    const tilesetsFirstgid: { [key: number]: any } = {};
    await Promise.all(
      tilesets.map(async (tileset: any) => {
        const tilesetFileName = tileset.source.split('.tsx')[0];
        const module = await import(`../../resources/tilesets/${tilesetFileName}.json`);
        tilesetsFirstgid[tileset.firstgid] = module.default;
      })
    );
    return tilesetsFirstgid;
  }
};
