/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { Student } from '../models/Student';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Queue User Emails
     * @returns any Successful Response
     * @throws ApiError
     */
    public static queueUserEmailsApiUserQueueEmailsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/queue_emails',
        });
    }

    /**
     * Send User Email From Queue
     * @param rows
     * @returns any Successful Response
     * @throws ApiError
     */
    public static sendUserEmailFromQueueApiUserSendEmailsGet(
        rows: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/send_emails',
            query: {
                'rows': rows,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Email Opened
     * @param emailId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static markEmailOpenedApiUserOpenEmailIdGet(
        emailId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/open/{email_id}',
            path: {
                'email_id': emailId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Mark Email Opened
     * @param emailId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static markEmailOpenedApiUserUnsubscribeEmailIdGet(
        emailId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/unsubscribe/{email_id}',
            path: {
                'email_id': emailId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Organizations
     * @param query
     * @returns Organization Successful Response
     * @throws ApiError
     */
    public static searchOrganizationsApiUserSearchOrganizationsGet(
        query: string,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/organizations',
            query: {
                'query': query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Emails
     * @param query
     * @param organizationId
     * @returns User Successful Response
     * @throws ApiError
     */
    public static searchEmailsApiUserSearchEmailsGet(
        query: string,
        organizationId?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/emails',
            query: {
                'query': query,
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Student Usernames
     * @param query
     * @param organizationId
     * @returns Student Successful Response
     * @throws ApiError
     */
    public static searchStudentUsernamesApiUserSearchStudentUsernamesGet(
        query: string,
        organizationId?: string,
    ): CancelablePromise<Array<Student>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search/student_usernames',
            query: {
                'query': query,
                'organization_id': organizationId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Language
     * @param language
     * @returns any Successful Response
     * @throws ApiError
     */
    public static changeLanguageApiUserChangeLanguagePost(
        language: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/change-language',
            query: {
                'language': language,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Language
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getLanguageApiUserGetLanguageGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/get-language',
        });
    }

}
