/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExecutionType } from '../models/ExecutionType';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CreditService {

    /**
     * Get Credit
     * @param executionType
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCreditApiCreditExecutionTypeGet(
        executionType: ExecutionType,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/credit/{execution_type}',
            path: {
                'execution_type': executionType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
