import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {
  ApiError,
  ChallengeData,
  ChatGPTRequest,
  ChatGPTRequestLevels,
  ChatgptService,
  LevelData,
  TipResponseParsed
} from '../client';
import { useCode } from './CodeContext';
import { useCurrentUser } from '../hooks/useCurrentUser';
import useLevels from '../hooks/useLevels';
import { useParams } from 'react-router-dom';

export const VirtualAssistantContext = createContext<{
  tip?: TipResponseParsed;
  setTip: (tip: TipResponseParsed) => void;
  tipVisible: boolean;
  setTipVisible: (visible: boolean) => void;
  generateTipChallengeAsync?: (data: ChallengeData, errorMessage?: string | null) => Promise<void>;
  generateTipLevelAsync: (
    data: LevelData,
    playerPosition: number[],
    facingDirection: number[],
    errorMessage?: string | null
  ) => Promise<void>;
  setIsTipSwitcherActive: (active: boolean) => void;
  isActivationsExceeded: boolean;
  isTipSwitcherActive: boolean;
  playerPosition?: number[];
  facingDirection?: number[];
  setPlayerPosition: (position: number[]) => void;
  setFacingDirection: (direction: number[]) => void;
}>({
  tip: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTip: () => {},
  tipVisible: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTipVisible: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  generateTipChallengeAsync: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  generateTipLevelAsync: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsTipSwitcherActive: () => {},
  isActivationsExceeded: false,
  isTipSwitcherActive: false,
  playerPosition: [0, 0],
  facingDirection: [0, 0],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPlayerPosition: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFacingDirection: () => {}
});

export const VirtualAssistantProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [tip, setTip] = useState<TipResponseParsed>();
  const [tipVisible, setTipVisible] = useState(false);
  const [isActivationsExceeded, setIsActivationsExceeded] = useState(false);
  const [isTipSwitcherActive, setIsTipSwitcherActive] = useState<boolean>(
    Cookies.get('isTipSwitcherActive') === 'true' || !Cookies.get('isTipSwitcherActive')
  );
  const [playerPosition, setPlayerPosition] = useState<number[]>([0, 0]);
  const [facingDirection, setFacingDirection] = useState<number[]>([0, 0]);

  const { courseSlug, levelSlug } = useParams();
  const { currentUser } = useCurrentUser();
  const { code } = useCode();

  const generateTipChallengeAsync = async (data: ChallengeData, errorMessage?: string | null) => {
    if (!data) return;
    try {
      let error: string | null = null;

      if (errorMessage) {
        error = errorMessage;
      }

      const { description, solution } = data;

      const chatGPTRequest: ChatGPTRequest = {
        description,
        solution,
        last_prompt: code
      };

      if (error) {
        chatGPTRequest.error_message = error;
      }

      const tipRes =
        await ChatgptService.generateTipChallengeAsyncApiChatgptGenerateTipChallengeAsyncPost(
          chatGPTRequest
        );
      setTip(tipRes);
    } catch (e) {
      const statusCode = (e as ApiError).status;
      if (statusCode === 403) {
        if (!currentUser) {
          setIsActivationsExceeded(true);
          setTip({
            text: 'You are out of credits for the coding assistant! Please sign up to get more free credits so that I can continue helping you.',
            is_code_sufficient: false
          });
        } else if (currentUser.student_id) {
          setIsActivationsExceeded(true);
          setTip({
            text: 'You are out of credits for the coding assistant! Please upgrade to get unlimited credits so that I can continue helping you.',
            is_code_sufficient: false
          });
        }
      } else {
        console.error('Error fetching level data or generating tip:', e);
        setTip({
          text: 'Failed to fetch the tip. Please try again later.',
          is_code_sufficient: false
        });
      }
    }
  };

  const generateTipLevelAsync = async (
    data: LevelData,
    playerPosition: number[],
    facingDirection: number[],
    errorMessage?: string | null
  ) => {
    if (!data || !isTipSwitcherActive) return;
    try {
      let error: string | null = null;

      if (errorMessage) {
        error = errorMessage;
      }

      // const { tutorial, solution } = data;

      const chatGPTRequest: ChatGPTRequestLevels = {
        last_prompt: code,
        course_name: courseSlug || 'python',
        slug: levelSlug || 'intro-level1',
        player_position: playerPosition,
        facing_direction: facingDirection
      };

      if (error) {
        chatGPTRequest.error_message = error;
      }

      const tipRes =
        await ChatgptService.generateTipLevelAsyncApiChatgptGenerateTipLevelAsyncPost(
          chatGPTRequest
        );
      setTip(tipRes);
    } catch (e) {
      const statusCode = (e as ApiError).status;
      if (statusCode === 403) {
        if (!currentUser) {
          setIsActivationsExceeded(true);
          setTip({
            text: 'You are out of credits for the coding assistant! Please sign up to get more free credits so that I can continue helping you.',
            is_code_sufficient: false
          });
        } else if (currentUser.student_id) {
          setIsActivationsExceeded(true);
          setTip({
            text: 'You are out of credits for the coding assistant! Please upgrade to get unlimited credits so that I can continue helping you.',
            is_code_sufficient: false
          });
        }
      } else {
        console.error('Error fetching level data or generating tip:', e);
        setTip({
          text: 'Failed to fetch the tip. Please try again later.',
          is_code_sufficient: false
        });
      }
    }
  };

  return (
    <VirtualAssistantContext.Provider
      value={{
        tip,
        setTip,
        tipVisible,
        setTipVisible,
        generateTipChallengeAsync,
        generateTipLevelAsync,
        isActivationsExceeded,
        isTipSwitcherActive,
        setIsTipSwitcherActive,
        playerPosition,
        setPlayerPosition,
        facingDirection,
        setFacingDirection
      }}
    >
      {children}
    </VirtualAssistantContext.Provider>
  );
};

export const useVirtualAssistantContext = () => {
  return useContext(VirtualAssistantContext);
};
