/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface SidebarContextType {
  openSidebar: boolean;
  isSidebarClosing: boolean;
  sidebarWidth: number;
  handleDrawerClose: () => void;
  handleDrawerTransitionEnd: () => void;
  handleDrawerOpen: () => void;
}

const defaultContextValue: SidebarContextType = {
  openSidebar: false,
  isSidebarClosing: false,
  sidebarWidth: 300,
  handleDrawerClose: () => {},
  handleDrawerTransitionEnd: () => {},
  handleDrawerOpen: () => {}
};

const SidebarContext = createContext<SidebarContextType>(defaultContextValue);

export function useSidebar() {
  return useContext(SidebarContext);
}

interface SidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isSidebarClosing, setIsSidebarClosing] = useState(false);

  const sidebarWidth = 300;

  const handleDrawerClose = () => {
    setIsSidebarClosing(true);
    setOpenSidebar(false);
  };

  const handleDrawerOpen = () => {
    setOpenSidebar(true);
  };

  const handleDrawerTransitionEnd = () => {
    setIsSidebarClosing(false);
  };

  return (
    <SidebarContext.Provider
      value={{
        openSidebar,
        isSidebarClosing,
        sidebarWidth,
        handleDrawerClose,
        handleDrawerOpen,
        handleDrawerTransitionEnd
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
