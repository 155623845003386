/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserType {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
}
