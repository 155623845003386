import React, { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { SidebarProvider } from '../context/SidebarContext';
import { AppProvider } from '../context/AppContext';
import { useCurrentLocale } from '../hooks/useCurrentLocale';
import { languages } from '../components/LocaleSelect/languages';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import NotificationBar from '../components/NotificationBar';

const Feedback = React.lazy(() => import('../components/Feedback/Feedback'));
const AssignPurchaseModal = React.lazy(() => import('../components/AssignPurchaseModal'));
const AvatarSelectorGlobal = React.lazy(() => import('../components/AvatarSelectorGlobalModal'));

const supportedLocales = languages.map((lang) => lang.code);

const MemoizedFeedback = React.memo(Feedback);
const MemoizeAvatarSelectorGlobal = React.memo(AvatarSelectorGlobal);

export default function Layout() {
  const { data: locale, isLoading } = useCurrentLocale();
  const { lng } = useParams();
  const { pathname } = useLocation();

  const selectedLanguage = languages.find((lang) => lang.name === locale?.language) || languages[0];

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (lng && !supportedLocales.includes(lng)) {
      window.location.href = selectedLanguage
        ? `/${selectedLanguage.code}${pathname}`
        : `/en${pathname}`;
    }
  }, [lng, locale, selectedLanguage.code, selectedLanguage.name]);

  return (
    <AppProvider>
      <SidebarProvider>
        <NotificationBar />
        <Header />
        <Sidebar />
        <MemoizedFeedback />
        <AssignPurchaseModal />
        <MemoizeAvatarSelectorGlobal />
        <main
          style={{
            paddingTop: 64,
            background: '#2F3129',
            minHeight: 'calc(100vh - 64px)'
          }}
        >
          <Outlet />
        </main>
        {/* <Footer /> */}
      </SidebarProvider>
    </AppProvider>
  );
}
