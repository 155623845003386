import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { ApiError, LevelData, LevelService, UserType } from '../client';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useLocaleContext } from '../context/LocaleContext';
import { UserContext, UserTypeMap } from '../pages/App';
import python from '../assets/python-logo-white.png';
import { mapHelper } from '../@core/utils/mapHelper';

interface LevelsContextType {
  courseData: any;
  levelData: LevelData | undefined;
  levelDataLoading: boolean;
  courseDataLoading: boolean;
  courseId: string;
  levelId: string;
  activeCourse: string;
  setActiveCourse: React.Dispatch<React.SetStateAction<string>>;
  activeLevel: string;
  setActiveLevel: React.Dispatch<React.SetStateAction<string>>;
  courses: Array<{
    value: string;
    label: string;
    firstLevel: string;
    description: string;
    logo: string;
    chapters: string[];
  }>;
  tilesetsFirstgid: { [key: number]: any } | undefined;
}

const LevelsContext = createContext<LevelsContextType | undefined>(undefined);

export const LevelsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { courseSlug, levelSlug, studentId } = useParams();
  const { createSnackBar } = useSnackBarContext();
  const navigate = useNavigate();
  const { getLocaleLink } = useLocaleContext();
  const currentUser = useContext(UserContext);
  const [tilesetsFirstgid, setTilesetsFirstgid] = useState<{ [key: number]: any }>();
  const userType = UserTypeMap[currentUser?.user_type || UserType._0];

  const [activeCourse, setActiveCourse] = useState<string>('python');
  const [activeLevel, setActiveLevel] = useState<string>('intro-level1');

  const courses = [
    {
      value: 'python',
      label: 'Python Development Course',
      firstLevel: 'intro-level1',
      description: 'Learn Python programming language from scratch.',
      chapters: [
        'Variables and Types',
        'Basic Operators',
        'Loops',
        'Lists',
        'Conditions',
        'Functions',
        'Dictionaries',
        'Classes'
      ],
      logo: python
    }
  ];

  const courseId = courseSlug || activeCourse;
  const levelId = levelSlug || activeLevel;

  const { data: courseData, isLoading: courseDataLoading } = useQuery(
    `courseData-${currentUser?.user_id}-${studentId ?? currentUser?.student_id}-${courseId}`,
    async () => {
      return LevelService.getCourseDataRouteApiLevelCourseSlugGet(courseId, studentId);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
      cacheTime: 10000
    }
  );

  const { data: levelData, isLoading: levelDataLoading } = useQuery<LevelData, ApiError, LevelData>(
    `mapData-${courseId}-${levelId}`,
    async () => {
      return LevelService.getLevelDataRouteApiLevelDataCourseSlugSlugGet(courseId, levelId);
    },
    {
      onError: (error) => {
        console.log('error', error);
        switch (error.status) {
          case 403:
            createSnackBar({ content: error.body.detail, autoHide: true, severity: 'info' });
            break;
          case 402:
            if (userType === 'parent') window.location.href = getLocaleLink('/purchase');
            else if (userType === 'teacher' || userType === 'admin')
              window.location.href = getLocaleLink('/schools');
            break;
          case 401:
            navigate(getLocaleLink('/login'));
            break;
          default:
            createSnackBar({ content: 'An error occurred', autoHide: true, severity: 'error' });
            break;
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false
    }
  );

  useEffect(() => {
    if (!levelData) return;
    if (tilesetsFirstgid) return;
    mapHelper.getTilesetMapperDynamicJsonImports(levelData.map.tilesets).then((tilesets) => {
      setTilesetsFirstgid(tilesets);
    });
  }, [levelData]);

  return (
    <LevelsContext.Provider
      value={{
        courseData,
        levelData,
        levelDataLoading,
        courseDataLoading,
        courseId,
        levelId,
        activeCourse,
        setActiveCourse,
        activeLevel,
        setActiveLevel,
        courses,
        tilesetsFirstgid
      }}
    >
      {children}
    </LevelsContext.Provider>
  );
};

export const useLevels = () => {
  const context = useContext(LevelsContext);
  if (!context) {
    throw new Error('useLevels must be used within a LevelsProvider');
  }
  return context;
};
