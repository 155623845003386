/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface AppContextType {
  openChooseStudent: boolean;
  setOpenChooseStudent: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultContextValue: AppContextType = {
  openChooseStudent: false,
  setOpenChooseStudent: () => {}
};

const AppContext = createContext<AppContextType>(defaultContextValue);

export function useAppContext() {
  return useContext(AppContext);
}

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [openChooseStudent, setOpenChooseStudent] = useState(false);

  return (
    <AppContext.Provider
      value={{
        openChooseStudent,
        setOpenChooseStudent
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
