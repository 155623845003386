/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Certificate } from '../models/Certificate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CertificateService {

    /**
     * Get Certifications
     * @param studentId
     * @returns Certificate Successful Response
     * @throws ApiError
     */
    public static getCertificationsApiCertificateStudentStudentIdGet(
        studentId: string,
    ): CancelablePromise<Array<Certificate>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/certificate/student/{student_id}',
            path: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate Data
     * @param certId
     * @returns Certificate Successful Response
     * @throws ApiError
     */
    public static getCertificateDataApiCertificateCertIdGet(
        certId: string,
    ): CancelablePromise<Certificate> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/certificate/{cert_id}',
            path: {
                'cert_id': certId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate Data
     * @param certId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCertificateDataApiCertificateCertIdJpgGet(
        certId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/certificate/{cert_id}/jpg',
            path: {
                'cert_id': certId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate Data
     * @param certId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCertificateDataApiCertificateCertIdThumbnailGet(
        certId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/certificate/{cert_id}/thumbnail',
            path: {
                'cert_id': certId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate Data
     * @param certId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCertificateDataApiCertificateCertIdPdfGet(
        certId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/certificate/{cert_id}/pdf',
            path: {
                'cert_id': certId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
