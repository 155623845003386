/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseCompletion } from '../models/CourseCompletion';
import type { CourseData } from '../models/CourseData';
import type { LevelCompletion } from '../models/LevelCompletion';
import type { LevelData } from '../models/LevelData';
import type { LevelStatusUpdate } from '../models/LevelStatusUpdate';
import type { StudentProgress } from '../models/StudentProgress';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LevelService {

    /**
     * Get Guide Image
     * @param course
     * @param slug
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getGuideImageApiLevelGuideCourseSlugPngGet(
        course: string,
        slug: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/level/guide/{course}/{slug}.png',
            path: {
                'course': course,
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Level Data Route
     * @param courseSlug
     * @param slug
     * @returns LevelData Successful Response
     * @throws ApiError
     */
    public static getLevelDataRouteApiLevelDataCourseSlugSlugGet(
        courseSlug: string,
        slug: string,
    ): CancelablePromise<LevelData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/level/data/{course_slug}/{slug}',
            path: {
                'course_slug': courseSlug,
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Course Data Route
     * @param slug
     * @param studentId
     * @returns CourseData Successful Response
     * @throws ApiError
     */
    public static getCourseDataRouteApiLevelCourseSlugGet(
        slug: string,
        studentId?: string,
    ): CancelablePromise<CourseData> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/level/course/{slug}',
            path: {
                'slug': slug,
            },
            query: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org Student Completion Route
     * @param courseSlug
     * @returns StudentProgress Successful Response
     * @throws ApiError
     */
    public static getOrgStudentCompletionRouteApiLevelCourseCourseSlugOrgCompletionGet(
        courseSlug: string,
    ): CancelablePromise<Array<StudentProgress>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/level/course/{course_slug}/org-completion',
            path: {
                'course_slug': courseSlug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert Level Completion
     * @param slug
     * @param requestBody
     * @returns LevelCompletion Successful Response
     * @throws ApiError
     */
    public static upsertLevelCompletionApiLevelUpdateSlugPost(
        slug: string,
        requestBody: LevelStatusUpdate,
    ): CancelablePromise<LevelCompletion> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/level/update/{slug}',
            path: {
                'slug': slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate
     * @param slug
     * @returns CourseCompletion Successful Response
     * @throws ApiError
     */
    public static getCertificateApiLevelGetCertificateSlugGet(
        slug: string,
    ): CancelablePromise<CourseCompletion> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/level/get_certificate/{slug}',
            path: {
                'slug': slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
