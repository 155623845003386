/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CancelSubscriptionReasonEnum {
    FINISHED_COURSE = 'finished_course',
    DONT_PLAY_ANYMORE = 'dont_play_anymore',
    NOT_ENOUGH_CONTENT = 'not_enough_content',
    ENGINE_NOT_WORKING = 'engine_not_working',
    TOO_EXPENSIVE = 'too_expensive',
    OTHER = 'other',
}
