import { createContext, useContext, useState, useRef } from 'react';

interface CodeContextType {
  code: string;
  setCode: (newCode: string) => void;
  codeRef: React.MutableRefObject<string>;
}

const CodeContext = createContext<CodeContextType | null>(null);

export function useCode(): CodeContextType {
  return useContext(CodeContext) as CodeContextType;
}

export function CodeProvider({ children }: { children: React.ReactNode }) {
  const [code, setStateCode] = useState<string>("");
  const codeRef = useRef<string>("");

  const setCode = (newCode: string) => {
    setStateCode(newCode);
    codeRef.current = newCode; 
  };

  const value: CodeContextType = {
    code,
    setCode,
    codeRef
  };

  return <CodeContext.Provider value={value}>{children}</CodeContext.Provider>;
}
