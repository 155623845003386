import { Box, Stack } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import { Class, Student } from '../client';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

export default function StudentAvatar({
  student,
  classDetails,
  size = 32,
  variant
}: {
  student: Student;
  classDetails?: Class;
  size: number;
  variant?: Variant;
}) {
  return (
    <Box component="div" sx={{ display: 'flex', alignItems: 'center', ...(variant || classDetails ? { gap: 1 } : {}) }}>
      <Avatar sx={{ width: size, height: size }} alt={student.username}>
        <img
          src={`/assets/characters/portraits/${student.character ?? 'Rabbit'}.png`}
          width={size}
          height={size}
        />
      </Avatar>
      <Stack>
        {variant && <Typography variant={variant}>{student.username}</Typography>}
        {classDetails && (
          <Typography fontSize={12} color="text.secondary">
            Class: {classDetails.name}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
