import { Box, CircularProgress } from '@mui/material';
import { forwardRef, useEffect, useRef, useState } from 'react';

function isElementVisible(el: HTMLElement) {
  // Check if the element exists
  if (!el) {
    return false;
  }

  if (document.hidden) {
    return false;
  }

  // Get the bounding rectangle of the element
  const rect = el.getBoundingClientRect();

  // Check if the element is within the viewport
  const isVisible =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  return isVisible;
}

export const Loader = forwardRef((props, ref) => {
  const [visibleSeconds, setVisibleSeconds] = useState<number>(0);
  const progress = useRef<HTMLElement>();

  useEffect(() => {
    const timeout = setInterval(() => {
      if (!progress.current) {
        return;
      }
      if (isElementVisible(progress.current)) {
        setVisibleSeconds((v) => v + 1);
      } else {
        setVisibleSeconds(0);
      }
    }, 1000);
    return () => clearInterval(timeout);
  }, []);

  useEffect(() => {
    if (visibleSeconds === 15) {
      throw new Error('Loader visible for more than 15 seconds');
    }
  }, [visibleSeconds]);

  return (
    <Box
      component="div"
      ref={ref}
      {...props}
      sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress sx={{ my: 8 }} ref={progress} />
    </Box>
  );
});
